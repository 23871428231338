import React, { useEffect, useState } from 'react'
import { Select, InputLabel, TextField, Button, Checkbox, OutlinedInput } from '@mui/material';
import { Box, Card, Grid, FormControl, MenuItem, FormControlLabel } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import CommonAuth from '../../../../state/APIConnect/Common';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AppSettings } from '../../../../AppSettings';
import GoogleAddressAutoComplete from '../../../../components/AutoCompleteAddress/GoogleAddressAutoComplete';

const ReviewInstitutionPage = () => {
    const { institutionId } = useParams();
    const [provinceId, setProvinceId] = useState(0);
    const [categoryId, setCategoryId] = useState(0);
    const [districtMunicipalityId, setDistrictMunicipalityId] = useState(0);
    const [localsByDistrictMunicipalityId, setLocalsByDistrictMunicipalityId] = useState(0);
    const [corePurposeId, setCorePurposeId] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [mainOfficeaddress, setMainOfficeAddress] = useState('');
    const [alternativeNumber, setAlternativeNumber] = useState('');
    const [districtMunicipality, setDistrictMunicipality] = useState('');
    const [local, setLocal] = useState('');
    const [email, setEmail] = useState('');
    const [alternativeEmail, setAlternativeEmail] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [website, setWebsite] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [pagination] = useState({ pageIndex: 0, pageSize: 100 });
    const [corePurpose, setCorePurpose] = useState('');
    const [category, setCategory] = useState('');
    const [province, setProvince] = useState('');
    const [institution, setInstitution] = useState([]);
    const [decision, setDecision] = useState('');  // Store the selected value (Approve/Decline)
    const [declineReason, setDeclineReason] = useState(''); // Store the decline reason
    const [institutionStatus, setInstitutionStatus] = useState([]); // Store the decline reason


    const navigate = useNavigate();


    const handleBack = () => {
        navigate("/pendingInstitutions");
    }


    useEffect(() => {
        const GetInstitutionById = async () => {
            // if (!corePurposes.length) {
            //     setIsLoading(true);
            // }
            const res = await NSPMonitorAuth.GetInstitutionById(institutionId)
            const resInstitutionStatus = await NSPMonitorAuth.GetInstitutionVerificationStatusByInstitutionId(institutionId);
            setInstitutionStatus(resInstitutionStatus.content);
            let data = res.content;
            console.log("🚀 ~ GetInstitutionById ~ data:", data)
            if (data === '') {
                console.log('Content is null empty');
                setCorePurposeId();
                setCategoryId(data.categoryId)
            }
            else {
                setInstitution(res.content);
                setCategoryId(data.categoryId);
                setProvinceId(data.provinceId);
                setCorePurposeId(data.corePurposeId);
                setName(data.name);
                setDescription(data.description);
                setPhoneNumber(data.phoneNumber);
                setMainOfficeAddress(data.address);
                setAlternativeNumber(data.alternativeNumber);
                setEmail(data.email);
                setAlternativeEmail(data.alternativeEmail);
                setWebsite(data.website);
                setCorePurposeId(data.corePurposeId);
                setDistrictMunicipality(data.districtMunicipality);
                setCategory(data.category);
                setProvince(data.province);
                setCorePurpose(data.corePurpose);
                setLocal(data.local);
                setDeclineReason(data.declineReason);
                setDistrictMunicipalityId(data.districtMunicipalityId);
                setLocalsByDistrictMunicipalityId(data.localByDistrictMunicipalityId);
                setWebsite(data.websiteUrl);
            }
            setIsLoading(false);
        }


        GetInstitutionById();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async () => {
        var data = { "institutionId": institutionId, "decision": decision, "declineReason": declineReason };
        const resp = await NSPMonitorAuth.ReviewInstitution(data);
        if (resp.content !== null) {
            toast.success(resp.content.message, { position: "top-right", });
            navigate("/pendingInstitutions");
        }
        else {
            toast.error("Something went wrong. Please try again later.", { position: "top-right", });
        }
    }

    const handleDecisionChange = (event) => {
        setDecision(event.target.value);
    };

    const handleDeclineReasonChange = (event) => {
        setDeclineReason(event.target.value);
    };

    const isSubmitDisabled = () => {
        if (decision === 'Decline' && declineReason.trim().length <= 10) {
            return true; // Disable if decline is selected and reason is empty
        }
        return !decision; // Disable if no decision is selected
    };

    return (
        <Card sx={{ minWidth: 275 }}>
            <Box m="0.5rem 2.5rem">
                <Box sx={{ mt: 1 }}>
                    <h1>Review Institution</h1>

                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={8}>
                            <TextField disabled fullWidth label="Core Purpose *" value={corePurpose} onChange={e => setCorePurpose(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField disabled fullWidth label="Category *" value={category} onChange={e => setCategory(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField disabled fullWidth label="Province *" value={province} onChange={e => setProvince(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField disabled fullWidth label="District Municipality *" value={districtMunicipality} onChange={e => setDistrictMunicipality(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField disabled fullWidth label="Local District Municipality *" value={local} onChange={e => setLocal(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField disabled fullWidth label="Name" value={name} onChange={e => setName(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField disabled fullWidth multiline rows={2} label="Description" value={description} onChange={e => setDescription(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} >
                            <TextField disabled fullWidth label="Main Office *" value={mainOfficeaddress} onChange={e => setMainOfficeAddress(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField disabled fullWidth label="Phone Number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField disabled fullWidth label="Alternative Number" value={alternativeNumber} onChange={e => setAlternativeNumber(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField disabled fullWidth label="Email" value={email} onChange={e => setEmail(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField disabled fullWidth label="Alternative Email" value={alternativeEmail} onChange={e => setAlternativeEmail(e.target.value)} />
                        </Grid>

                        {/* <Grid item xs={12} sm={4}>
                            <Button component="label" variant="contained" onChange={handleFileChange} startIcon={<CloudUploadIcon />} >
                                Upload Logo  (Logo of contributing organization will be shared publicly) <input type="file" onChange={handleFileChange} hidden multiple />
                            </Button>
                            {selectedFiles.length > 0 && (
                                <div>
                                    <p>Selected Files:</p>
                                    <ul>
                                        {selectedFiles.map((file, index) => (
                                            <li key={index}>{file.name}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </Grid> */}

                        <Grid item xs={12} sm={8}>
                            <TextField disabled fullWidth label="Website URL" value={website} onChange={e => setWebsite(e.target.value)} />
                        </Grid>

                        <Grid item xs={12} sm={8}>
                            {institutionStatus?.length > 0 && (
                                <Grid item xs={12}>
                                    <ul>
                                        {institutionStatus.map((organizationType, index) => (
                                            <li key={index}>{organizationType.name}</li>
                                        ))}
                                    </ul>
                                </Grid>
                            )}

                        </Grid>

                        {declineReason && (
                            <Grid item xs={12} sm={12}>
                                <p>Institution was previously declined for the following reason:</p>
                                <ul>
                                    <li>{declineReason}</li>
                                </ul>
                            </Grid>
                        )}


                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <InputLabel id="decision-label">Decision</InputLabel>
                                <Select
                                    labelId="decision-label"
                                    id="decision"
                                    value={decision}
                                    label="Decision"
                                    onChange={handleDecisionChange}>
                                    <MenuItem value="Approve">Approve</MenuItem>
                                    <MenuItem value="Decline">Decline</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} sm={12}>
                            {decision === 'Decline' && (
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        label="Decline Reason"
                                        fullWidth
                                        required
                                        multiline
                                        rows={4}
                                        value={declineReason}
                                        onChange={handleDeclineReasonChange}
                                    />
                                </Grid>
                            )}
                        </Grid>

                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Button onClick={() => handleBack()}
                                color='primary'
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}>
                                Back
                            </Button>
                        </Grid>


                        <Grid item xs={12} sm={4}>
                            <Button disabled={isSubmitDisabled() || isLoading}
                                color='primary'
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handleSubmit} >
                                Submit
                            </Button>
                        </Grid>


                    </Grid>

                </Box>
            </Box>
        </Card >
    )
}

export default ReviewInstitutionPage